import React from 'react';
import Lottie from 'react-lottie';
import LoaderAnimation from '../assets/lottie/loader.json'

const LoaderComponent = () => {
    const options = {
        loop: true,
        autoplay: true,
        animationData: LoaderAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
        <Lottie 
          options={options}
          height={200}
          width={200}
        />
    );
}

export default LoaderComponent;