import React, { useEffect, useState } from 'react';
import './App.css';
import './loader.css'
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from './components/CardComponent';
import LoaderComponent from './components/LoaderComponent';
import axios from 'axios';
import { resetScore } from './store/actions/scoreActions';

function App() {
  const store = useSelector((store) => store.scoreReducer);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  const init = () => {
    dispatch(resetScore());
    setData(null);

    const task = async () => {
      setTimeout(() => {
        axios.get("https://wordassociationgame.api.ackhava.com/").then(rdata => setData(rdata));
      }, 0);
    };

    task();
  }

  useEffect(init, [])
  
  return (
    <React.Fragment>
      <header>
        <h1>Word Association Game!</h1>
        <p>Your score: {store.score} </p>
      </header>
      
      <main className="questions">
        {data === null ? (<LoaderComponent />) : 
          (data.data.data.map((mdata) => <CardComponent data={mdata} key={mdata}></CardComponent>))
        }
      </main>

      <footer>
        <p>Want to try again?</p>
        <button onClick={init} className="question-button">Retry</button>
      </footer>
    </React.Fragment>
  );
}

export default App;
