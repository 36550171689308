import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {createStore, combineReducers} from "redux";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import { scoreReducer } from './store/reducers/scoreReducer';

const container = document.getElementById('root');
const root = createRoot(container);

const reducer = combineReducers({scoreReducer: scoreReducer});
const store = createStore(reducer);

root.render(
  <React.StrictMode> 
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
