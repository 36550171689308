const initialState = {
  score: 0
}

export const scoreReducer = (state = initialState, { type }) => {
  switch (type) {
  case "INCREMENT_SCORE":
    return { ...state, score: (state.score + 1) }
    
  case "DECREMENT_SCORE":
    return { ...state, score: (state.score - 1) }
  
  case "RESET_SCORE":
    return { ...state, score: 0 }

  default:
    return state
  }
}
