import React, { useState } from "react";
import ButtonComponent from "./ButtonComponent";

const CardComponent = ({ data }) => {
    const [isCorrect, setIsCorrect] = useState(null);

    return (
        <div className="question-box">
            {data.quiz.map((q) => {return (<p key={q}>{q}</p>)})}
            <div className="question-buttons">
                <ButtonComponent isCorrect={data.correct === 1} text={data.options[0]} setIsCorrect={setIsCorrect} />
                <ButtonComponent isCorrect={data.correct === 2} text={data.options[1]} setIsCorrect={setIsCorrect} />
            </div>
            <div className={((isCorrect !== null) ? (isCorrect ? "correct" : "wrong") : "") + " answer-display"}>{((isCorrect !== null) ? (isCorrect ? "Correct!" : "Wrong!") : "") }</div>
        </div>
    )
}

export default CardComponent