export const incrementScore = () => ({
  type: "INCREMENT_SCORE",
})

export const decrementScore = () => ({
    type: "DECREMENT_SCORE",
})

export const resetScore = () => ({
  type: "RESET_SCORE",
})
  