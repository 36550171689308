import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { decrementScore, incrementScore } from "../store/actions/scoreActions";

const ButtonComponent = ({isCorrect, text, setIsCorrect}) => {
    const [pressed, setPressed] = useState(false);
    const dispatch = useDispatch();

    const handleClick = () => {
        if (pressed) return;
        setPressed(true);
        setIsCorrect(isCorrect)
        if (isCorrect)
            dispatch(incrementScore());
        else
            dispatch(decrementScore());
    }

    return (
        <button onClick={handleClick} className="question-button" disabled={pressed}>{text}</button>
    )
}

export default ButtonComponent